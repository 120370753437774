<template>
  <div class="UserCoupons_I">
    <div class="headNavs">
      <div class="headTilte">用户优惠券</div>
      <div class="headDetails">说明：包含用户优惠券的优惠券名称、门槛金额、优惠金额、有效时间、使用范围及添加时间</div>
    </div>

    <!-- 搜索导航 -->
    <div class="searchNav">
      <div class="NavLi">
          <div class="NavLiName">优惠券名称：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.coupon_name" placeholder="搜索优惠券名称"></el-input>
          </div>
      </div>
      <div class="NavLi">
          <div class="NavLiName">用户昵称：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.nickname" placeholder="搜索用户昵称"></el-input>
          </div>
      </div>
      <div class="NavLi">
        <div class="NavLiName">手机号：</div>
        <div class="NavLiIpt">
          <el-input v-model="search.mobile" placeholder="搜索手机号"></el-input>
        </div>
        <div class="NavLiQuery" style="margin-left:24px" @click="BtnSearch()">查询</div>
        <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
      </div>
      <div class="NavLi" v-for="obj in 5" :key="obj"></div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入优惠券名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="user_coupon_id" label="ID" width="60" align="center"></el-table-column>
        <el-table-column prop="nickname" label="用户"  align="center" width="150">
          <template #default="scope">
              <span>{{ scope.row.user ? scope.row.user.nickname : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号"  align="center" width="130">
          <template #default="scope">
            <span>{{ scope.row.user ? scope.row.user.mobile : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cate_name" label="优惠券名称"  align="center" width="250">
          <template #default="scope">
            <span>{{ scope.row.coupon ? scope.row.coupon.cate_name : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="优惠券门槛金额" align="center" width="120">
          <template #default="scope">
            <span>{{ scope.row.coupon ? scope.row.coupon.total : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="discount" label="优惠金额"  align="center">
          <template #default="scope">
              <span>{{ scope.row.coupon.discount ? scope.row.coupon.discount : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="start_time" label="生效时间" width="150" align="center"></el-table-column>
        <el-table-column prop="end_time" label="截止时间" width="150" align="center"></el-table-column>
        <el-table-column prop="valid" label="状态"  align="center">
          <template #default="scope">
              <span>{{ {0:'隐藏',1:'显示'}[scope.row.valid||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="use_time" label="使用时间" width="150" align="center">
            <template #default="scope">
              <span>{{ scope.row.use_time ? scope.row.use_time  :'未使用' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="添加时间" width="150" align="center"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      Row: { 
        name: "",
        type: [],
        dedicated: "",
        status: "",
        discount: '',
        total: "",
        description: "",
        goods_ids:[],
      },
      //搜索参数
      search: {
        keyword: "",
        nickname:'',
        coupon_name:'',  
        mobile:'',  
      },
      total: 0,
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/coupon/userCoupon',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
           keyword : '',
           coupon_name : '',
           nickname : '',
           mobile : '',
        };
      }
      that.loadTable()
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      console.log(page);
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
  }
}
</script>
 